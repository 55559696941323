<!--
 * @Description: 物料属性面板
 * @Autor: WangYuan
 * @Date: 2022-01-13 15:31:38
 * @LastEditors: WangYuan
 * @LastEditTime: 2022-01-28 11:49:52
-->
<template>
  <div class="Config">
    <config-ctn v-if="control.curWidget" :title="control.curWidget.name">
      <custom-schema-template v-if="control.curWidget.id !== 'top'" :schema="curSchema" v-model="control.curWidget"
        :key="control.curWidget.id"></custom-schema-template>

      <config-wrap v-else>
        <el-form :model="form" ref="form" label-width="80px">
          <div>
            <div class="title">页面名称</div>
            <el-input v-model="form.headName" placeholder="请输入页面名称" size="small"></el-input>
          </div>
          <div style="position: relative;">
            <div class="title mt15">导航样式</div>
            <el-radio-group v-model="form.navigationBar">
              <el-radio label="followShop" value="followShop">跟随店铺导航</el-radio>
              <div class="navigationBar-config mt8 mb8">
                在店铺导航-小程序导航中统一设置
                <span style="cursor: pointer;color: #409EFF;" @click="bindJump">前往设置</span>
              </div>

              <el-radio label="custom" value="custom">自定义样式</el-radio>
              <div class="navigationBar-config mt8 mb8">可设置隐藏顶部导航（沉浸式），可设置搜索、购物车、店铺主页等快捷入口</div>
            </el-radio-group>
          </div>

          <div v-if="form.navigationBar == 'custom'">
            <el-form-item label="顶部风格" prop="name">
              <img src="https://cdn.zhaosheng.daheyun1314.com/training/306eeef730094e1090ebfb51e629dd3c.png"
                @click="selectTopType('default')" class="topImg" :class="[form.topType == 'default' ? 'active' : '']"
                style="margin-left: 105px;" />
              <img src="https://cdn.zhaosheng.daheyun1314.com/training/f72e84c4570d4f95a4ac61f7584daa8f.png"
                @click="selectTopType('soak')" class="topImg" :class="[form.topType == 'soak' ? 'active' : '']"
                style="margin-left: 10px;" />
              <div v-if="form.topType == 'soak'" class="f12 mt8 mb8 text-right lh14" style="color: #969799;">
                建议把图片/多图文设为页面第一个模块</div>

              <!-- 沉浸模式 -->
              <el-form-item v-if="form.topType == 'soak'" label="沉浸模式" style="color: #969799;">
                <div class="flex items-center" style="line-height: 28px;">
                  <el-radio-group v-model="form.soak" style="width: 95px;font-size: 16px;">
                    <el-radio style="line-height: 28px;" label="1" value="1">永久沉浸</el-radio>
                    <el-radio style="line-height: 28px;" label="2" value="2">滑动恢复</el-radio>
                  </el-radio-group>

                  <!-- 查看示例 -->
                  <el-popover placement="left" trigger="hover">
                    <div class="flex p15">
                      <div class="mr50">
                        <div style="font-weight: 600;margin: 5px 0 20px;font-size: 20px;">永久沉浸模式</div>
                        <img class="w180" src="../../assets/image/soak2.gif" alt="" />
                      </div>
                      <div>
                        <div style="font-weight: 600;margin: 5px 0 20px;font-size: 20px;">滑动恢复模式</div>
                        <img class="w180" src="../../assets/image/soak1.gif" alt="" />
                      </div>
                    </div>
                    <div slot="reference">
                      <div style="cursor: pointer;color: #409EFF;">【查看示例】</div>
                      <div style="cursor: pointer;color: #409EFF;">【查看示例】</div>
                    </div>
                  </el-popover>
                </div>
              </el-form-item>
            </el-form-item>

            <el-form-item label="标题位置" prop="seat">
              <el-radio-group v-model="form.seat" size="small" style="margin-left: 125px;">
                <el-radio-button label="center" value="center">居中</el-radio-button>
                <el-radio-button label="left" value="left">居左</el-radio-button>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="标题颜色" prop="titleColor">
              <el-radio-group v-model="form.titleColor" size="small" style="margin-left: 125px;">
                <el-radio-button label="#000" value="#000">黑色</el-radio-button>
                <el-radio-button label="#fff" value="#fff">白色</el-radio-button>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="背景颜色">
              <config-color-picker v-model="form.backgroundColor"></config-color-picker>
            </el-form-item>
          </div>
          <div class="mt15"></div>
        </el-form>
      </config-wrap>
    </config-ctn>

    <config-ctn v-else title="页面配置">
      <page-config></page-config>
    </config-ctn>
  </div>
</template>

<script>
import { getAssetConfig } from "@/api/goods";
import {mapGetters, mapMutations} from "vuex";
import PageConfig from "@/components/PageConfig";
export default {
  components: {
    PageConfig
  },
  inject: ["control"],
  data() {
    return {
      visible: false,
      viewExamples: 1,
      form: {
        component: 'navTop',
        topType: 'default',
        headName: '',
        navigationBar: '',
        soak: '',
        seat: 'center',
        titleColor: '#000',
        backgroundColor: '#fff',
      },
      assteValue: []
    }
  },
  watch: {
    "form": {
      handler() {


        if (this.form.navigationBar == 'custom') {
          this.$store.commit('pageTop', this.form);
        }

        if (this.form.navigationBar == 'followShop') {
          let topNavigation = this.project.configEntity.topNavigation.styles
          this.form.seat = topNavigation.seat == '1' ? 'left' : 'center';
          this.form.titleColor = topNavigation.titleColor == '1' ? '#000' : '#fff';
          this.form.backgroundColor = topNavigation.backgroundColor ? topNavigation.backgroundColor : '#fff';
        }
        let info = this.control.curPage.componentEntityList?.find(a => a.component == "navTop")
        let index = this.control.curPage.componentEntityList?.findIndex(a => a.component == "navTop")
        if (info) {
          this.control.curPage.componentEntityList[index] = this.form
        } else {
          if (!this.control.curPage.componentEntityList) {
            this.control.curPage.componentEntityList = [];
          }
          this.control.curPage.componentEntityList.unshift(this.form);
        }
        //保存
      //  this.setCurPage(this.control.curPage);
        console.log('监测名字变化监测名字变化监测名字变化监测名字变化监测名字变化监测名字变化:',this.control.curPage)

      },
      deep: true,
    },
  },
  computed: {
    ...mapGetters(["project", "curPage"]),
    ...mapMutations(["setCurPage", "setProject"]),

    curSchema() {
      if (this.control.curWidget.component == 'McAssets') {
        let data = this.$fields[this.control.curWidget.component]
        for (let i in data) {
          if (i == 'customerSet' && !this.assteValue || i == 'customerSet' && this.assteValue.length == 0) {
            delete data[i]
          }
        }
        this.$fields[this.control.curWidget.component] = data
      }
      return this.$fields[this.control.curWidget.component];
    },
  },
  created() {
    getAssetConfig({ enableDisable: true }).then(res => {
      res.data.forEach(ele => {
        this.assteValue.push({ name: ele.name, userAssetSign: ele.userAssetSign });
      });
    }).catch(err => {
      reject(err)
    });


    let info = this.control.curPage.componentEntityList?.find(a => a.component == "navTop")

     console.log("infoinfoinfoinfoinfoinfoinfo:",info);

    if (this.form.navigationBar == 'followShop') {

      let topNavigation = JSON.parse(this.project.configEntity.topNavigation).styles
      this.form.seat = topNavigation.seat == '1' ? 'left' : 'center';
      this.form.titleColor = topNavigation.titleColor == '1' ? '#000' : '#fff';
      this.form.backgroundColor = topNavigation.backgroundColor ? topNavigation.backgroundColor : '#fff';

    } else if (info) {

      this.form = info;

    } else {


      this.form = {
        component: 'navTop',
        topType: 'default',
        headName: this.control.curPage.name,
        navigationBar: 'custom',
        soak: '',
        seat: 'center',
        titleColor: '#000',
        backgroundColor: '#fff',
      }


    }


    console.log("this.control.curPage1111111:",this.curPage.componentList);




  },
  methods: {
    selectTopType(type) {
      this.form.topType = type;
    },

    ViewExamples(val) {
      console.log(val);
      this.viewExamples = val;
      this.visible = !this.visible;
    },

    bindJump() {
      this.$router.push({ path: "/mall/control/list-tpl" });
    }
  },
};
</script>

<style lang="scss">
.title {
  line-height: 40px;
  font-weight: 600;
}

.topImg {
  width: 62px;
  padding: 5px;
  cursor: pointer;
}

.active {
  border: 1px solid #3894ff;
  border-radius: 3px;
}

.navigationBar-config {
  color: #969799;
  margin: 0 6px 0 16px;
  font-size: 12px;
  line-height: 18px;
}
</style>
