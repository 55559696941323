<template>
    <div class="layout">
        <div v-if="isShow" class="layout-open">
            <div class="preview-page-header">
                页面布局
                <span @click="onSwitch(false)" class="el-icon-close"
                    style="line-height: inherit;cursor: pointer;"></span>
            </div>
            <div class="preview-page-content">
                <div class="decorate-component-coms" @click="checkedSchema()"
                    :class="[isCheckId == 'top'&&control.curWidget!=undefined ? 'active' : '']" style="padding-left: 35px;">
                    1、顶部导航
                </div>

                <draggable v-model="control.curPage.componentEntityList">
                    <div v-for="(item, index) in componentList" :key="item.id" class="decorate-component-coms"
                        @click="checkedSchema(item)"
                         :class="[isCheckId == item.id ? 'active' : '']">
                        <img src="https://img01.yzcdn.cn/upload_files/2022/05/30/Fpc0Dar7FLznf_yX6b1hb93kD_k2.png" />
                        {{ index + 2 }}、{{ item.name }}
                    </div>
                </draggable>

            </div>

            <div class="preview-page-footer" @click="control.curWidget = undefined" :style="control.curWidget == undefined?'background:rgb(230, 239, 255);border-color:#3894ff':''">
                <!-- <span class="el-icon-setting"></span> -->
                <img src="https://b.yzcdn.cn/wsc-pc-decorate/page-setting@2x.png" alt="">
                页面设置
            </div>

        </div>

        <div v-else class="preview-page-coms" @click="onSwitch(true)">
            <span class="el-icon-files"></span>
            页面布局
        </div>
    </div>
</template>

<script>

export default {
    name: "controlLayout",
    inject: ["control"],

    // provide() {
    //     return {
    //         control: this.control,
    //     };
    // },

    data() {
        return {
            isShow: true,
            isCheckId: '',
            componentList: [],
        }
    },
    watch: {
        "control.curPage.componentEntityList": {
            handler(newValue, oldValue) {

              this.componentList = newValue?.filter(item => item.component !== 'navTop');
              //取差集 获取最后一次拖的组件
                if(oldValue){

                  //添加组件 取差集
                  if(this.componentList.length+1>oldValue.length){
                    var newArray = this.componentList.filter(
                        (item) => !oldValue.some((e) => e.id === item.id)
                    )

                    if(newArray&&newArray.length>0){
                      //&&newArray[0]['isReturn']!=false
                      console.log("newArray[0]:",newArray[0]);
                      if(newArray[0]['isReturn']!=false){
                        this.control.curWidget=newArray[0];
                        this.isCheckId = newArray[0].id;
                      }

                    }
                    else{
                      this.isCheckId = 'top';
                    }

                  }
                  else{

                    var newArray = oldValue.filter(
                        (item) => !newValue.some((e) => e.id === item.id)
                    )
                    if(newArray.length==1&&newArray[0].id==this.isCheckId){
                      this.isCheckId = 'top';
                    }
                  }

                }
                else{
                 // console.log("老数据没值");
                  this.isCheckId = 'top';
                }



            },
            deep: true,
            immediate: true,
        },
        "control.curWidget": {
            handler() {

                if (this.control.curWidget && this.control.curWidget.id !== 'top') {
                    let row = this.control.curPage.componentEntityList.find(ele => ele.id == this.control.curWidget.id)
                    if (row) this.isCheckId = row.id;
                } else {
                    this.isCheckId = 'top';
                }

                // console.log("==>>------", this.isCheckId);
            },
            deep: true,
        }
    },
    methods: {
        onSwitch(item) {
            this.isShow = item;
        },
        checkedSchema(row) {
          console.log("row:",row);
            if (row) {
                this.control.curWidget = row;
            } else {
                // this.control.curWidget = undefined;
                this.control.curWidget = { id: 'top', name: '顶部导航' };
            }
            // console.log("control", this.control);
        },

    },
}
</script>

<style lang='scss'>
.layout {
    position: fixed;
    right: 380px;
    z-index: 10;
    border-right: 1px solid #e0e0e0;
    box-shadow: -5px 0 10px -5px rgba(0, 0, 0, .1);
    background-color: #fff;
    height: calc(100% - 56px);
    font-size: 16px;
    line-height: 24px;
}

.layout-open {
    width: 168px;
    height: 100%;
    background-color: #fff;
}

.preview-page-header {
    padding: 12px 16px;
    display: flex;
    justify-content: space-between;
    background-color: #f7f7f7;
}

.preview-page-content {
    height: calc(100% - 95px);
    overflow-y: auto;

    .decorate-component-coms {
        padding: 12px 16px;
        border-bottom: 1px solid #e0e0e0;
        cursor: pointer;

        img {
            width: 16px;
            height: 16px;
            cursor: grab;
        }
    }

    .active {
        background-color: rgb(230, 239, 255);
    }
}

.preview-page-footer {
    // height: 48px;
    line-height: 18px;
    padding: 14px 16px;
    cursor: pointer;
    background-color: #fff;
    border-top: 1px solid #e0e0e0;

    img {
        width: 16px;
        height: 16px;
        margin: -2px 3px 0 0;
        background-size: cover;
    }
}

.preview-page-coms {
    width: 104px;
    height: 36px;
    line-height: 36px;
    text-align: center;
    position: fixed;
    top: 80px;
    right: 376px;
    background-color: #fff;
    cursor: pointer;
}
</style>
