
<!--
 * @Description: 页面配置
 * @Autor: WangYuan
 * @Date: 2021-09-22 17:33:51
 * @LastEditors: WangYuan
 * @LastEditTime: 2022-01-18 16:10:43
-->
<template>
  <div class="p10">
    <!--      <config-color-picker v-model="project.configEntity.backgroundColor"></config-color-picker>-->


    <config-item label="背景设置">
      <div style="display: flex;flex-wrap: wrap;justify-content: flex-end;">
        <div v-for="(item, index) in tagList" :key="index"
             :class="[item.value == curPage.backgroundColor.status ? 'mode-select-item-active' : '']" class="mode-select-item flex-center"
             @click="clickItem(item)">
          <span>{{ item.label }}</span>
        </div>
      </div>
    </config-item>


    <div class="select-label" v-show="curPage.backgroundColor.status == 'color'">
      <span class="select-label-text" style="width: 100px;">背景颜色</span>
      <span class="select-label-text" style="margin-left: 24px;">{{ curPage.backgroundColor.color }}</span>

      <config-color-picker v-model="curPage.backgroundColor.color"  cate="view"></config-color-picker>

    </div>
    <config-item label="图片" v-show="curPage.backgroundColor.status == 'image'">
      <div style="display: flex;flex-wrap: wrap;justify-content: flex-end;">
        <SchemaUpload label="" v-model="curPage.backgroundColor.url" />
      </div>
    </config-item>
    <config-item label="图片缩放" v-show="curPage.backgroundColor.status == 'image'">
      <div style="display: flex;flex-wrap: wrap;justify-content: flex-end;">
        <el-select v-model="curPage.backgroundColor.zoom" placeholder="请选择" size="small" style="width: 75%;">
          <el-option v-for="item in list" :key="item.value" :label="item.label" :value="item.value"></el-option>
        </el-select>
      </div>
    </config-item>

    <!--      <config-color-picker v-model="curPage.backgroundColor"></config-color-picker>-->


  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "PageConfig",
  computed: {
    ...mapGetters(["project",'curPage']),
  },
  data() {
    return {
      tagList: [
        { label: "颜色填充", value: "color" },
        { label: "图片", value: "image" },
      ],
      list: [
        { label: "填充", value: "cover" },
        { label: "等比例", value: "contain" },
        { label: "平铺", value: "repeat" },
        { label: "拉伸", value: "stretch" },
        { label: "原图", value: "artwork" },
      ]
    };
  },
  methods: {
    clickItem(data) {

      if(data.value=='color'){
        this.curPage.backgroundColor.color='#f5f5f5';
      }
      else{
        this.curPage.backgroundColor.url="";
      }

      this.curPage.backgroundColor.status = data.value;
      this.$forceUpdate();

    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../scss/variable";

.mode-select-item {
  padding: 8px 12px;
  min-width: 55px;
  border: 1px solid #dcdee0;
  /*no*/
  border-radius: 0;
  font-size: 12px;
  /*no*/
  font-weight: 500;
  text-align: center;
  cursor: pointer;

  &:hover {
    color: $color-theme;
    border-color: $color-theme;
    background-color: #e0edff;

    i {
      color: $color-theme;
    }
  }

  i {
    color: #979797;
  }
}

.mode-select-item-active {
  color: $color-theme;
  border-color: $color-theme;
  background-color: #e0edff;

  i {
    color: $color-theme;
  }
}

.select-label {
  margin: 0 27px 0 10px;
  display: flex;
  align-items: center;

  .select-label-text {

    color: #969799;
    font-size: 13px;
  }
}
</style>
